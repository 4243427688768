<template>
    <div>
        <ValidationObserver ref="priceFormModalValidate">
            <b-row>
                <b-col cols="12" md="6" lg="4" v-if="false">
                    <Validation-provider name="unihall_quota_id" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('unihall_quota')">
                            <unihall-quota-selectbox :disabled="disabled" v-model="formData.unihall_quota_id"
                                :validate-error="errors[0]" />
                        </b-form-group>
                    </Validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <Validation-provider name="currency" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('currency')">
                            <b-form-select :disabled="disabled" v-model="formData.currency"
                                :class="errors[0] ? 'is-invalid' : ''"
                                :options="[{ text: 'TRY', value: 'TRY' }, { text: 'USD', value: 'USD' }]"
                                :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </Validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <Validation-provider name="installment" rules="required|numeric" v-slot="{ errors }">
                        <b-form-group :label="$t('installment')">
                            <b-form-input :disabled="disabled" v-model="formData.installment" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </Validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <Validation-provider name="price" rules="required|double" v-slot="{ errors }">
                        <b-form-group :label="$t('price')">
                            <b-form-input :disabled="disabled" v-model="formData.price" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </Validation-provider>
                </b-col>
            

            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button class="px-5" variant="primary" @click="createForm" :disabled="loading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>
<script>

// Services
import UnihallQuotaPriceService from '@/services/UnihallQuotaPriceService';

export default {
    name: "UnihallQuotaPrice",
    props: {
        formType: {
            type: String,
            required: true
        },
        formId: {
            type: Number,
            default: -1
        },
        value: {
            type: Object,
            default: () => { }
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
        }
    },
    watch: {
        formType: {
            handler() {
                this.$refs.priceFormModalValidate.reset();
            },
        }
    },
    computed: {
        formData: {
            get() {
                return this.value
            },
            set(val) {

                this.$emit("input", val)
                return val

            }
        },
        buttonText() {
            if (this.formType === "show") {
                return this.$t('close')
            } else if (this.formType === "add") {
                return this.$t('save')
            }
            else if (this.formType === "update") {
                return this.$t('update')
            }
            return ''
        }
    },
    methods: {
        async createForm() {
            if (this.formType === "show") {
                this.$emit('close')
                return
            }
            const valid = await this.$refs.priceFormModalValidate.validate()
            if (!valid) {
                return
            }
            this.loading = true

            let response = null
            
            this.formData.unihall_quota_id = this.formId
            this.formData.price = Number(Number(this.formData.price).toFixed(2))
            
            switch (this.formType) {
                case "add":
                    response = await UnihallQuotaPriceService.store(this.formData).catch(e => {
                        this.showErrors(e, this.$refs.priceFormModalValidate);
                    })
                        .finally(() => {
                            this.loading = false
                        })
                    break;

                case "update":
                  
                    response = await UnihallQuotaPriceService.update(this.formId, this.formData).catch(e => {
                        this.showErrors(e, this.$refs.priceFormModalValidate);
                    })
                        .finally(() => {
                            this.loading = false
                        })

                    break;

                default:
                    break;
            }

            if (!response) {
                this.$toast.error(this.$t('something_went_wrong'));
                return
            }


            if (response.data.success) {
                this.formClear()
                this.$refs.priceFormModalValidate.reset();
                if (this.formType === "update") {
                    this.$emit('close')
                }
            }
            this.$toast.success(this.$t('api.' + response.data.message));
        },
        formClear() {
            this.formData = {}
        }
    },
}
</script>
<style lang="">
    
</style>